import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/download"

const Support = ({ data, location }) => {
  const title = "Do pobrania"

  return (
    <Layout
      location={location}
      seo={{
        title: "Do pobrania",
      }}
      rmLangSwitcher
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content data={data} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo0OTA5" } }) {
      nodes {
        acfDownload {
          downloadContent {
            name
            size
            cover {
              localFile {
                publicURL
              }
            }
            pdf {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`

export default Support
