import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import IcoDownload from "assets/icons/download-v2.svg"

const Content = ({ data }) => {
  const { t } = useLocale()

  return (
    <section className="download-content">
      <div className="container-fluid">
        <h2>{t("Najnowsze")}</h2>
        <div className="row">
          {data?.allWpPage?.nodes[0]?.acfDownload?.downloadContent
            ?.slice(0, 3)
            .map((item, index) => (
              <div className="col-md-4 col-sm-6" key={index}>
                <div className="download-content__item">
                  <div
                    className="download-content__item-image"
                    style={{
                      backgroundImage: `url('${item?.cover?.localFile?.publicURL}')`,
                    }}
                  />
                  <a
                    href={item?.pdf?.localFile?.publicURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={IcoDownload} alt="" />{" "}
                    <p>
                      {item?.name} <span>({item?.size} MB)</span>
                    </p>
                  </a>
                </div>
              </div>
            ))}
        </div>
        {data?.allWpPage?.nodes[0]?.acfDownload?.downloadContent.length > 3 && (
          <div className="download-content__archive">
            <h3>{t("Archiwum")}</h3>
            {data?.allWpPage?.nodes[0]?.acfDownload?.downloadContent
              ?.slice(3)
              .map((item, index) => (
                <a
                  href={item?.pdf?.localFile?.publicURL}
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                >
                  <strong>
                    {item?.name} <span>({item?.size} MB)</span>
                  </strong>{" "}
                  <img src={IcoDownload} alt="" />
                </a>
              ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default Content
